// import React from 'react'
import smoothscroll from 'smoothscroll-polyfill'

export const onClientEntry = () => {
  smoothscroll.polyfill()
}

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `This website has been updated. Reload to display the latest version?`,
  )
  if (answer === true) {
    window.location.reload()
  }
}
